@font-face {
    font-family: 'Raleway';
    src: url('Raleway-Heavy.woff2') format('woff2'),
        url('Raleway-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-Bold.woff2') format('woff2'),
        url('Raleway-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-SemiBold.woff2') format('woff2'),
        url('Raleway-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway.woff2') format('woff2'),
        url('Raleway.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-ExtraBold.woff2') format('woff2'),
        url('Raleway-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-Thin.woff2') format('woff2'),
        url('Raleway-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-ExtraLight.woff2') format('woff2'),
        url('Raleway-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-Light.woff2') format('woff2'),
        url('Raleway-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-Medium.woff2') format('woff2'),
        url('Raleway-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

