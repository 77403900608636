@import (reference) '~antd/dist/antd.less';

.table-striped-rows {
  tbody tr:nth-child(2n) td {
    background-color: @white;
  }

  .ant-pagination {
    .ant-pagination-item {
      font-weight: bold;
      font-size: @font-size-sm;
    }

    .ant-pagination-options {
      height: 40px;

      .ant-pagination-options-size-changer,
      .ant-select-selector {
        height: 100%;

        .ant-select-selection-item {
          line-height: 3;
        }
      }
    }
  }

  .ant-pagination-item-active:focus,
  .ant-pagination-item-active:hover {
    a {
      color: @primary-color;
    }
  }
}

.table-action-rows {
  tbody tr {
    cursor: pointer;
  }
}

.ant-table-column-sorters {
  justify-content: space-between;
  width: 100%;
}

.ant-table-thead {
  .ant-table-cell {
    text-transform: capitalize;
  }
}

.ant-table {
  overflow-x: auto;
}
