.ant-form-item-children-icon {
  width: 28px !important;
}
.ant-form-item-control-input {
  .ant-form-item-control-input-content {
    .ant-input {
      padding-left: 20px;
      padding-right: 20px;
    }

    .ant-select {
      .ant-select-selector {
        padding-left: 20px;
      }
    }
  }
}

.ant-form-item-label {
  > label {
    color: @black-2;
    width: 100%;
    &.ant-form-item-required {
      &::before {
        color: @slate-blue !important;
        position: absolute;
        right: 0;
        font-family: Raleway;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
      }
    }
  }
}

.ant-picker:not(.clearable) {
  .anticon-close-circle{
    display: none !important;
  }
}

.ant-form-item-has-feedback .ant-picker {
  padding-right: 11px !important;
}
